.DeliveryMap {
    display: inline-block;
    width:100%;
    height:100%;
    border: 1px #dadada solid;
}

#root, #app, #map {
    height: 100%;
  }
  

