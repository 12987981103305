.LeftSide {
    color: #000;
    display: inline-table;
    align-content: right;
    text-align: right;
    width:30%;
}
.Middle {
    color: #000;
    align-content: left;
    text-align: left;
    display: inline-table;
    width:50%;
}
.RightSide {
    color: #000;
    align-content: left;
    text-align: left;
    display: inline-table;
    width:20%;
}