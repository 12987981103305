#root, #app, #map {
  height: 100%;
}

#state {
  width: 200px;
  float: left;
}


html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
.Header_Header__2sLhf {
    height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #703B09;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}
.Header_Heading__2aAmt {
    color: #FFF;
    -webkit-align-content: center;
            align-content: center;
    text-align: center;
    width:100%;
}
.Header_Logo__1AC1m {
    height: 80%;
}

.Logo_Logo__1Cmb1 {
    background-color: white;
    padding: 8px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
}

.Logo_Logo__1Cmb1 img {
    height: 100%;
}
.Input_Input__30ZEn {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Input_Label__Zbrkh {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.Input_InputElement__2CVN1 {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.Input_InputElement__2CVN1:focus {
    outline: none;
    background-color: #ccc;
}

.Input_Invalid__E7FGj {
    border: 1px solid red;
    background-color: #FDA49A;
}
.Button_Button__igHx_ {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
}

.Button_Button__igHx_:first-of-type {
    margin-left: 0;
    padding-left: 0;
}

.Button_Button__igHx_:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Button_Success__3qesX {
    color: #5C9210;
}

.Button_Help__2_q82 {
    color: #119233;
}

.Button_Danger__3ssRN {
    color: #944317;
}
.Spinner_Loader__392kP,
.Spinner_Loader__392kP:before,
.Spinner_Loader__392kP:after {
  border-radius: 50%;
}
.Spinner_Loader__392kP {
  color: #521751;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.Spinner_Loader__392kP:before,
.Spinner_Loader__392kP:after {
  position: absolute;
  content: '';
}
.Spinner_Loader__392kP:before {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: Spinner_load2__1hen6 2s infinite ease 1.5s;
  animation: Spinner_load2__1hen6 2s infinite ease 1.5s;
}
.Spinner_Loader__392kP:after {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: Spinner_load2__1hen6 2s infinite ease;
  animation: Spinner_load2__1hen6 2s infinite ease;
}
@-webkit-keyframes Spinner_load2__1hen6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes Spinner_load2__1hen6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.Auth_Auth__1s5DS {
    margin: 20px auto;
    margin-top: 72px;
    width: 80%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;
}

@media (min-width: 600px) {
    .Auth_Auth__1s5DS {
        width: 500px;
    }
}
.App {
    margin: 20px auto;
    width: 90%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;
}


.DeliveryMapOptions_DeliveryMapOptions__3arje {
    margin: 20px auto;
    width: 100%;
    -webkit-align-content: center;
            align-content: center;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;
}
.DeliveryMap_DeliveryMap__jwrQ6 {
    display: inline-block;
    width:100%;
    height:100%;
    border: 1px #dadada solid;
}

#DeliveryMap_root__3JJI-, #DeliveryMap_app__3DQPA, #DeliveryMap_map__3NOpt {
    height: 100%;
  }
  


@font-face {
    font-family: Poppins-Regular;
    src: url(/static/media/Poppins-Regular.731a28a4.ttf); 
  }
  
  @font-face {
    font-family: Poppins-Bold;
    src: url(/static/media/Poppins-Bold.7940efc4.ttf); 
  }

  .Table_maxarea__hnjuU {
    width: 98%;
    margin: 0 auto;
  }
  
  .Table_fulltable__9yJ19 {
    width: 98%;
    min-height: 98;
    background: #c4d3f6;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 5px 5px;
  }
  
  .Table_wraptable__3CIUc {
    width: 98%;
    border-radius: 10px;
    overflow: hidden;
    overflow-x: scroll
  }
  
  .Table_table__19Puj {
    width: 100%;
    display: table;
    margin: 0;
    overflow-x: scroll
  }
  
  /* @media screen and (max-width: 768px) { 
    .table {
      display: block;
    }
  }*/
  
  .Table_row__1Upzt {
    display: table-row;
    background: #fff;
  }
  
  .Table_row__1Upzt.Table_header__z8PaX {
    color: #ffffff;
    background: #6c7ae0;
  }
  
  /*@media screen and (max-width: 768px) {
    .row {
      display: block;
    }
  
    .row.header {
      padding: 0;
      height: 0px;
    }
  
    .row.header .cell {
      display: none;
    }
  
    .row .cell:before {
      font-family: Poppins-Bold;
      font-size: 12px;
      color: #808080;
      line-height: 1.2;
      text-transform: uppercase;
      font-weight: unset !important;
  
      margin-bottom: 13px;
      content: attr(data-title);
      min-width: 98px;
      display: block;
    }
  }
  */
  .Table_cell__1ZuPx {
    display: table-cell;
  }
  
  /*@media screen and (max-width: 768px) {
    .cell {
      display: block;
    }
  }
  */
  
  .Table_row__1Upzt .Table_cell__1ZuPx {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #666666;
    line-height: 1.2;
    font-weight: unset !important;
  
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
  }
  
  .Table_row__1Upzt.Table_header__z8PaX .Table_cell__1ZuPx {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: unset !important;
  
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .Table_table__19Puj, .Table_row__1Upzt {
    width: 100% !important;
  }
  
  .Table_row__1Upzt:hover {
    background-color: #ececff;
    cursor: pointer;
  }

  .Table_full__2pZBy {
    display: table-cell;
  }
  @media (max-width: 500px) {
    .Table_full__2pZBy {
      display: none;
    }
    .Table_fulltable__9yJ19 {
      width: 100%;
      background: #c4d3f6;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-start;
              align-items: flex-start;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      padding: 5px 5px;
    }
  }
  
  /*
  @media (max-width: 768px) {
    .row {
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 18px;
      padding-top: 30px;
      padding-right: 15px;
      margin: 0;
    }
    
    .row .cell {
      border: none;
      padding-left: 30px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    
    
  
  }*/

  

  /*.table, .row, .cell {
    width: 100% !important;
  }*/

.DeliveryOrders_DeliveryOrders__oj_Bg {
    
    width: 100%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 5px;
    box-sizing: border-box;
    }
    a {
      cursor: pointer;
      color: blue;
    }
.DeliveryOrder_DeliveryOrder__3yM-e {
    margin: 20px auto;
    width: 100%;
    -webkit-align-content: center;
            align-content: center;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;
}


.SplitLabel_LeftSide__1dR7k {
    color: #000;
    display: inline-table;
    -webkit-align-content: right;
            align-content: right;
    text-align: right;
    width:30%;
}
.SplitLabel_RightSide__2kTCA {
    color: #000;
    -webkit-align-content: left;
            align-content: left;
    text-align: left;
    display: inline-table;
    width:70%;
}
.SplitLabel3_LeftSide__NQSp7 {
    color: #000;
    display: inline-table;
    -webkit-align-content: right;
            align-content: right;
    text-align: right;
    width:30%;
}
.SplitLabel3_Middle__z1XJG {
    color: #000;
    -webkit-align-content: left;
            align-content: left;
    text-align: left;
    display: inline-table;
    width:50%;
}
.SplitLabel3_RightSide__1HX3B {
    color: #000;
    -webkit-align-content: left;
            align-content: left;
    text-align: left;
    display: inline-table;
    width:20%;
}
.Modal_Modal__2zqR2 {
    position:  fixed;
    z-index: 500;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 10%;
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}



@media (min-width: 600px) {
    .Modal_Modal__2zqR2 {
        width: 500px;
        left: calc(50% - 250px);
    }
}
.Backdrop_Backdrop__3Dy8E {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.DeliveryOrderButtons_DeliveryOrderButtons__1SHSI {
    margin: 20px auto;
    width: 80%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;
}
.Toolbar_Toolbar__23GHW {
    height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #703B09;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar_Toolbar__23GHW nav {
    height: 100%;
}

.Toolbar_Logo__o7liU {
    height: 80%;
}

@media (max-width: 499px) {
    .Toolbar_DesktopOnly__3ztBS {
        display: none;
    }
}
.NavigationItems_NavigationItems__2nhvz {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
}

@media (min-width: 500px) {
    .NavigationItems_NavigationItems__2nhvz {
        -webkit-flex-flow: row;
                flex-flow: row;
    }
}
.NavigationItem_NavigationItem__SoOVb {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem_NavigationItem__SoOVb a {
    color: #8F5C2C;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem_NavigationItem__SoOVb a:hover,
.NavigationItem_NavigationItem__SoOVb a:active,
.NavigationItem_NavigationItem__SoOVb a.NavigationItem_active__32VWy {
    color: #40A4C8;
}

@media (min-width: 500px) {
    .NavigationItem_NavigationItem__SoOVb {
        margin: 0;
        display: -webkit-flex;
        display: flex;
        height: 100%;
        width: auto;
        -webkit-align-items: center;
                align-items: center;
    }
    
    .NavigationItem_NavigationItem__SoOVb a {
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationItem_NavigationItem__SoOVb a:hover,
    .NavigationItem_NavigationItem__SoOVb a:active,
    .NavigationItem_NavigationItem__SoOVb a.NavigationItem_active__32VWy {
        background-color: #8F5C2C;
        border-bottom: 4px solid #40A4C8;
        color: white;
    }
}
.DrawerToggle_DrawerToggle__36WgQ {
    width: 40px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle_DrawerToggle__36WgQ div {
    width: 90%;
    height: 3px;
    background-color: white;
}

@media (min-width: 500px) {
    .DrawerToggle_DrawerToggle__36WgQ {
        display: none;
    }
}
.SideDrawer_SideDrawer__2MTsE {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media (min-width: 500px) {
    .SideDrawer_SideDrawer__2MTsE {
        display: none;
    }
}

.SideDrawer_Open__15p45 {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.SideDrawer_Close__3SOpw {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.SideDrawer_Logo__2w-Ch {
    height: 11%;
    width:11%;
    margin-bottom: 32px;
}
