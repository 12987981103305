#root, #app, #map {
  height: 100%;
}

#state {
  width: 200px;
  float: left;
}


html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}