.DeliveryOrders {
    
    width: 100%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 5px;
    box-sizing: border-box;
    }
    a {
      cursor: pointer;
      color: blue;
    }