@font-face {
    font-family: Poppins-Regular;
    src: url('./poppins/Poppins-Regular.ttf'); 
  }
  
  @font-face {
    font-family: Poppins-Bold;
    src: url('./poppins/Poppins-Bold.ttf'); 
  }

  .maxarea {
    width: 98%;
    margin: 0 auto;
  }
  
  .fulltable {
    width: 98%;
    min-height: 98;
    background: #c4d3f6;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px 5px;
  }
  
  .wraptable {
    width: 98%;
    border-radius: 10px;
    overflow: hidden;
    overflow-x: scroll
  }
  
  .table {
    width: 100%;
    display: table;
    margin: 0;
    overflow-x: scroll
  }
  
  /* @media screen and (max-width: 768px) { 
    .table {
      display: block;
    }
  }*/
  
  .row {
    display: table-row;
    background: #fff;
  }
  
  .row.header {
    color: #ffffff;
    background: #6c7ae0;
  }
  
  /*@media screen and (max-width: 768px) {
    .row {
      display: block;
    }
  
    .row.header {
      padding: 0;
      height: 0px;
    }
  
    .row.header .cell {
      display: none;
    }
  
    .row .cell:before {
      font-family: Poppins-Bold;
      font-size: 12px;
      color: #808080;
      line-height: 1.2;
      text-transform: uppercase;
      font-weight: unset !important;
  
      margin-bottom: 13px;
      content: attr(data-title);
      min-width: 98px;
      display: block;
    }
  }
  */
  .cell {
    display: table-cell;
  }
  
  /*@media screen and (max-width: 768px) {
    .cell {
      display: block;
    }
  }
  */
  
  .row .cell {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #666666;
    line-height: 1.2;
    font-weight: unset !important;
  
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
  }
  
  .row.header .cell {
    font-family: Poppins-Regular;
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: unset !important;
  
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .table, .row {
    width: 100% !important;
  }
  
  .row:hover {
    background-color: #ececff;
    cursor: pointer;
  }

  .full {
    display: table-cell;
  }
  @media (max-width: 500px) {
    .full {
      display: none;
    }
    .fulltable {
      width: 100%;
      background: #c4d3f6;
    
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      padding: 5px 5px;
    }
  }
  
  /*
  @media (max-width: 768px) {
    .row {
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 18px;
      padding-top: 30px;
      padding-right: 15px;
      margin: 0;
    }
    
    .row .cell {
      border: none;
      padding-left: 30px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    
    
  
  }*/

  

  /*.table, .row, .cell {
    width: 100% !important;
  }*/
