.Modal {
    position:  fixed;
    z-index: 500;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 10%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}



@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}